<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
          <b-tabs card content-class="mt-3">
            <b-tab :title="$t('forms.basicInfo')" active>
                <b-row>
                    <b-col cols-lg="6">

                        <b-form-group
                            :class="{
                              row,
                              error: v$.itemCategorySelect.$errors.length,
                            }"
                            :label="$t('forms.itemCategory')"
                            label-for="itemCategorySelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isOtherItemForm()"
                        >
                            <v-select
                                id="itemCategorySelect"
                                v-model="itemCategorySelect"
                                :options="itemCategories"
                                :on-change="setFieldsVisibility()"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.itemCategorySelect.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.number.$errors.length }"
                            :label="$t('forms.confedNo')"
                            label-for="number"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('number')"
                        >
                            <b-form-input v-model="item.number" id="number" />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.number.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                      <b-form-group
                          :class="{ row, error: v$.navisionItemSelect.$errors.length }"
                          :label="$t('forms.navisionItem')"
                          label-for="navisionItemSelect"
                          label-cols-md="4"
                          content-cols-lg="8"
                          v-if="isFieldVisible('navisionItemSelect')"
                      >
                        <custom-select
                          id="navisionItemSelect"
                          :value="navisionItemSelect"
                          :options="navisionItems"
                          resource="$Items"
                          resource-type="item"
                          entity-type="NAVISION_ITEM"
                          @input="navisionItemSelect=$event"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.navisionItemSelect.$errors"
                            :key="error.$uid"
                        >
                          <small class="text-danger">{{ error.$message }}</small>
                        </div>
                      </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.name.$errors.length }"
                            :label="$t('forms.name')"
                            label-for="name"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('name')"
                        >
                            <b-form-input v-model="item.name" id="name" />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.name.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.description')"
                            label-for="description"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('description')"
                        >
                            <b-form-textarea v-model="item.description" id="description" />
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.description2')"
                            label-for="description2"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('description2')"
                        >
                            <b-form-textarea v-model="item.description2" id="description2" />
                        </b-form-group>

                        <b-form-group
                            :class="{
                          row,
                          error: v$.categorySelect.$errors.length,
                        }"
                            :label="$t('forms.category')"
                            label-for="categorySelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('category')"
                        >
                            <v-select
                                id="categorySelect"
                                v-model="categorySelect"
                                :options="categories"
                                v-on:close="updateItemCategorySelect"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.categorySelect.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{
                            row,
                            error: v$.typeSelect.$errors.length,
                          }"
                            :label="$t('forms.type')"
                            label-for="typeSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('typeSelect')"
                        >
                          <v-select
                              id="typeSelect"
                              v-model="typeSelect"
                              :options="types"
                          />
                          <div
                              class="input-errors"
                              v-for="error of v$.typeSelect.$errors"
                              :key="error.$uid"
                          >
                            <small class="text-danger">{{ error.$message }}</small>
                          </div>
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.type')"
                            label-for="layboardTypeSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('layboardTypeSelect')"
                        >
                          <v-select
                              id="layboardTypeSelect"
                              v-model="layboardTypeSelect"
                              :options="layboardTypes"
                          />
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.model.$errors.length }"
                            :label="$t('forms.model')"
                            label-for="model"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('model')"
                        >
                            <b-form-input v-model="item.model" id="model" />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.model.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                      <b-form-group
                          :class="{
                          row,
                          error: v$.departmentSelect.$errors.length,
                        }"
                          :label="$t('forms.department')"
                          label-for="departmentSelect"
                          label-cols-md="4"
                          content-cols-lg="8"
                          v-if="isFieldVisible('departmentSelect')"
                      >
                        <v-select
                            id="departmentSelect"
                            v-model="departmentSelect"
                            :options="departments"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.departmentSelect.$errors"
                            :key="error.$uid"
                        >
                          <small class="text-danger">{{ error.$message }}</small>
                        </div>
                      </b-form-group>

                      <b-form-group
                          :class="{
                          row,
                          error: v$.navisionTypeSelect.$errors.length,
                        }"
                          :label="$t('forms.type')"
                          label-for="navisionTypeSelect"
                          label-cols-md="4"
                          content-cols-lg="8"
                          v-if="isFieldVisible('navisionTypeSelect')"
                      >
                        <v-select
                            id="navisionTypeSelect"
                            v-model="navisionTypeSelect"
                            :options="navisionTypes"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.navisionTypeSelect.$errors"
                            :key="error.$uid"
                        >
                          <small class="text-danger">{{ error.$message }}</small>
                        </div>
                      </b-form-group>

                      <b-form-group
                          :class="{
                          row,
                          error: v$.locationSelect.$errors.length,
                        }"
                          :label="$t('forms.location')"
                          label-for="locationSelect"
                          label-cols-md="4"
                          content-cols-lg="8"
                          v-if="isFieldVisible('locationSelect')"
                      >
                        <v-select
                            id="locationSelect"
                            v-model="locationSelect"
                            :options="locations"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.locationSelect.$errors"
                            :key="error.$uid"
                        >
                          <small class="text-danger">{{ error.$message }}</small>
                        </div>
                      </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.itemConditionSelect.$errors.length }"
                            :label="$t('forms.condition')"
                            label-for="condition"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('itemConditionSelect')"
                        >
                            <v-select
                                id="itemConditionSelect"
                                v-model="itemConditionSelect"
                                :options="itemConditions"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.itemConditionSelect.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{
                              row,
                              error: v$.maintenanceTypeSelect.$errors.length,
                            }"
                            :label="$t('forms.maintenanceType')"
                            label-for="maintenanceTypeSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('maintenanceTypeSelect')"
                        >
                          <v-select
                              id="maintenanceTypeSelect"
                              v-model="maintenanceTypeSelect"
                              :options="maintenanceTypes"
                          />
                          <div
                              class="input-errors"
                              v-for="error of v$.maintenanceTypeSelect.$errors"
                              :key="error.$uid"
                          >
                            <small class="text-danger">{{ error.$message }}</small>
                          </div>
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.intervalInDays')"
                            label-for="interval"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('interval')"
                        >
                            <b-form-input type="number" v-model="item.itemInterval" id="interval" />
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.lastDate')"
                            label-for="lastDate"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('lastDate')"
                        >
                            <datepicker
                                id="lastDate"
                                v-model="item.lastDate"
                                :enableTimePicker="false"
                                format="dd. MM. yyyy"
                                previewFormat="dd. MM. yyyy"
                                class="form-input"
                            />
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.nextDate')"
                            label-for="nextDate"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('nextDate')"
                        >
                            <datepicker
                                id="nextDate"
                                v-model="item.nextDate"
                                :enableTimePicker="false"
                                format="dd. MM. yyyy"
                                previewFormat="dd. MM. yyyy"
                                class="form-input"
                            />
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.label.$errors.length }"
                            :label="$t('forms.label')"
                            label-for="label"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('label')"
                        >
                            <b-form-input v-model="item.label" id="label" />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.label.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{
                          row,
                          error: v$.userSelect.$errors.length,
                        }"
                            :label="$t('forms.user')"
                            label-for="userSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('userSelect')"
                        >
                            <custom-select
                              id="userSelect"
                              :value="userSelect"
                              :options="users"
                              resource="$Users"
                              resource-type="employee"
                              @input="userSelect=$event"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.userSelect.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.serviceTag.$errors.length }"
                            :label="$t('forms.serviceTag')"
                            label-for="serviceTag"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('serviceTag')"
                        >
                            <b-form-input v-model="item.serviceTag" id="serviceTag" />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.serviceTag.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.toolSelect.$errors.length }"
                            :label="$t('forms.tool')"
                            label-for="toolSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('toolSelect')"
                        >
                            <custom-select
                              id="toolSelect"
                              :value="toolSelect"
                              :options="tools"
                              resource="$Items"
                              resource-type="item"
                              entity-type="TOOL"
                              @input="toolSelect=$event"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.toolSelect.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.toolsSelect.$errors.length }"
                            :label="$t('forms.tools')"
                            label-for="toolsSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('toolsSelect')"
                        >
                            <custom-select
                              id="toolsSelect"
                              :value="toolsSelect"
                              :options="tools"
                              resource="$Items"
                              resource-type="item"
                              entity-type="TOOL"
                              @input="toolsSelect=$event"
                              multiple
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.toolsSelect.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.crossReference.$errors.length }"
                            :label="$t('forms.crossReference')"
                            label-for="crossReference"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('crossReference')"
                        >
                            <b-form-input v-model="item.crossReference" id="crossReference" />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.crossReference.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.minQuantity.$errors.length }"
                            :label="$t('forms.minQuantity')"
                            label-for="minQuantity"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('minQuantity')"
                        >
                            <b-form-input
                                type="number"
                                min="1"
                                step="1"
                                v-model="item.minQuantity"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.minQuantity.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.piece.$errors.length }"
                            :label="$t('forms.piece')"
                            label-for="piece"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('piece')"
                        >
                            <b-form-input
                                type="number"
                                min="1"
                                step="1"
                                v-model="item.piece"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.piece.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.statusSelect.$errors.length }"
                            :label="$t('forms.status')"
                            label-for="statusSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('statusSelect')"
                        >
                            <v-select
                                id="statusSelect"
                                v-model="statusSelect"
                                :options="statuses"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.statusSelect.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.status')"
                            label-for="statusText"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('statusText')"
                        >
                          <b-form-input v-model="item.statusText" id="statusText" />
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.hyperlink')"
                            label-for="hyperlink"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('hyperlink')"
                        >
                            <b-form-input v-model="item.hyperlink" id="hyperlink" />
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.stock.$errors.length }"
                            :label="$t('forms.stock')"
                            label-for="stock"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('stock')"
                        >
                            <b-form-input
                                type="number"
                                min="1"
                                step="1"
                                v-model="item.stock"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.stock.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.minTorque.$errors.length }"
                            :label="$t('forms.minTorque')"
                            label-for="minTorque"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('minTorque')"
                        >
                            <b-form-input
                                v-model="item.minTorque"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.minTorque.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.maxTorque.$errors.length }"
                            :label="$t('forms.maxTorque')"
                            label-for="maxTorque"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('maxTorque')"
                        >
                            <b-form-input
                                v-model="item.maxTorque"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.maxTorque.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.manufacturer.$errors.length }"
                            :label="$t('forms.manufacturer')"
                            label-for="manufacturer"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('manufacturer')"
                        >
                            <b-form-input v-model="item.manufacturer" id="manufacturer" />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.manufacturer.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.grommet')"
                            label-for="grometSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('grometSelect')"
                        >
                            <custom-select
                              id="grometSelect"
                              :value="grometSelect"
                              :options="navisionItems"
                              resource="$Items"
                              resource-type="item"
                              entity-type="NAVISION_ITEM"
                              @input="grometSelect=$event"
                            />
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.applicator')"
                            label-for="applicatorSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('applicatorSelect')"
                        >
                            <custom-select
                              id="applicatorSelect"
                              :value="applicatorSelect"
                              :options="applicatorTools"
                              resource="$Items"
                              resource-type="item"
                              entity-type="TOOL"
                              category-name="Applicator"
                              @input="applicatorSelect=$event"
                            />
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.applicator2')"
                            label-for="applicator2Select"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('applicator2Select')"
                        >
                            <custom-select
                              id="applicator2Select"
                              :value="applicator2Select"
                              :options="applicatorTools"
                              resource="$Items"
                              resource-type="item"
                              entity-type="TOOL"
                              category-name="Applicator"
                              @input="applicator2Select=$event"
                            />
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.handtoolSelect.$errors.length }"
                            :label="$t('forms.handtool')"
                            label-for="handtoolSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('handtoolSelect')"
                        >
                            <custom-select
                              id="handtoolSelect"
                              :value="handtoolSelect"
                              :options="handTools"
                              resource="$Items"
                              resource-type="item"
                              entity-type="TOOL"
                              category-name="Handtool"
                              @input="handtoolSelect=$event"
                            />
                          <div
                              class="input-errors"
                              v-for="error of v$.handtoolSelect.$errors"
                              :key="error.$uid"
                          >
                            <small class="text-danger">{{ error.$message }}</small>
                          </div>
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.airpress')"
                            label-for="airpressSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('airpressSelect')"
                        >
                            <custom-select
                              id="airpressSelect"
                              :value="airpressSelect"
                              :options="tools"
                              resource="$Items"
                              resource-type="item"
                              entity-type="TOOL"
                              @input="airpressSelect=$event"
                            />
                        </b-form-group>

                      <b-form-group
                          :label="$t('forms.strippingLength')"
                          label-for="strippingLength"
                          label-cols-md="4"
                          content-cols-lg="8"
                          v-if="isFieldVisible('strippingLength')"
                      >
                        <b-form-input v-model="item.strippingLength" id="strippingLength" />
                      </b-form-group>

                      <b-form-group
                          :label="$t('forms.handtoolLocation')"
                          label-for="handtoolLocation"
                          label-cols-md="4"
                          content-cols-lg="8"
                          v-if="isFieldVisible('handtoolLocation')"
                      >
                        <b-form-input v-model="item.handtoolLocation" id="handtoolLocation" />
                      </b-form-group>

                        <b-form-group
                            :label="$t('forms.airpressLocation')"
                            label-for="airpressLocation"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('airpressLocation')"
                        >
                            <b-form-input v-model="item.airpressLocation" id="airpressLocation" />
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.wireArea.$errors.length }"
                            :label="$t('forms.wireArea')"
                            label-for="wireArea"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('wireArea')"
                        >
                            <b-form-input v-model="item.wireArea" id="wireArea" />
                            <div
                                class="input-errors"
                                v-for="error of v$.item.wireArea.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.ch.$errors.length }"
                            :label="$t('forms.ch')"
                            label-for="ch"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('ch')"
                        >
                            <b-form-input v-model="item.ch" id="ch" />
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.chIso')"
                            label-for="chIso"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('chIso')"
                        >
                            <b-form-input v-model="item.chIso" id="chIso" />
                        </b-form-group>

                        <b-form-group
                            :label="$t('forms.controlled')"
                            label-for="controlledSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('controlledSelect')"
                        >
                          <v-select
                              id="controlledSelect"
                              v-model="controlledSelect"
                              :options="itemControlleds"
                          />
                        </b-form-group>

                        <b-form-group
                            :class="{ row, error: v$.item.remark.$errors.length }"
                            :label="$t('forms.remark')"
                            label-for="remark"
                            label-cols-md="4"
                            content-cols-lg="8"
                            v-if="isFieldVisible('remark')"
                        >
                            <b-form-input v-model="item.remark" id="remark" />
                        </b-form-group>

                      <b-form-group
                          :label="$t('forms.size')"
                          label-for="sizeSelect"
                          label-cols-md="4"
                          content-cols-lg="8"
                          v-if="isFieldVisible('sizeSelect')"
                      >
                        <v-select
                            id="sizeSelect"
                            v-model="sizeSelect"
                            :options="sizes"
                        />
                      </b-form-group>

                      <b-form-group
                          :label="$t('forms.note')"
                          label-for="note"
                          label-cols-md="4"
                          content-cols-lg="8"
                          v-if="isFieldVisible('note')"
                      >
                          <b-form-input v-model="item.note" id="size" />
                      </b-form-group>

                      <b-row
                              v-if="isFieldVisible('picture')"
                      >
                          <b-col lg="4">
                              <label for="">{{ $t('forms.picture') }}</label>
                          </b-col>
                          <b-col lg="8">
                              <image-upload :preview-image="fileURL" @fileUploaded="setPhoto"/>
                          </b-col>
                      </b-row>

                    </b-col>
                    <b-col cols-lg="6">

                    </b-col>
                </b-row>
            </b-tab>
            <b-tab :title="$t('forms.vendors')" v-if="isTabVisible('vendors')">
              <b-row>
                <b-col>

                  <b-form-group class="add-vendor-group">
                    <label for="">{{ $t("forms.vendor") }}</label>
                    <custom-select
                        id="selectedVendor"
                        :value="selectedVendor"
                        :options="vendors"
                        resource="$Vendors"
                        resource-type="entity"
                        @input="selectedVendor=$event"
                    />
                  </b-form-group>

                  <b-button
                      class="add-vendor-btn"
                      :disabled="!selectedVendor"
                      @click="addVendorToItem"
                      variant="primary"
                  >{{ $t("vendor.add") }}
                  </b-button
                  >

                  <b-alert
                      class="mt-3"
                      variant="danger"
                      :show="v$.itemVendors.$errors.length"
                  >{{ $t("forms.minItemVendor") }}
                  </b-alert
                  >

                  <div
                      v-if="itemVendors && itemVendors.length > 0"
                      class="custom-table table-vendors"
                  >
                    <table-lite
                        :is-slot-mode="true"
                        :columns="columns"
                        :rows="itemVendors"
                        :sortable="{}"
                    >
                      <template v-slot:vendor="data">
                        {{ data.value.vendorSelect.label }}
                      </template>
                      <template v-slot:vendorItemNumber="data">
                        <b-form-input
                            v-model="data.value.vendorItemNumber"
                        />
                      </template>
                      <template v-slot:price="data">
                        <b-form-input
                            v-model="data.value.price"
                        />
                      </template>
                      <template v-slot:leadTime="data">
                        <b-form-input
                            v-model="data.value.leadTime"
                        />
                      </template>
                      <template v-slot:reqQuantity="data">
                        <b-form-input
                            v-model="data.value.reqQuantity"
                        />
                      </template>
                      <template v-slot:actions="data">
                        <div class="just-buttons">
                          <delete-btn @pressDelete="removeItemVendor(data.value)" />
                        </div>
                      </template>
                    </table-lite>
                  </div>


                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('forms.history')" v-if="isTabVisible('history')">
              <b-row>
                <b-col>

                  <div
                      v-if="item.historiesDetails && item.historiesDetails.length"
                      class="custom-table"
                  >
                    <table-lite
                        :is-slot-mode="true"
                        :columns="historyColumns"
                        :rows="item.historiesDetails"
                        :sortable="{}"
                    >
                      <template v-slot:user="data">
                        <div v-if="data.value.user">
                          {{ data.value.user.givenName }} {{ data.value.user.familyName }}
                        </div>
                      </template>
                      <template v-slot:createdAt="data">
                        {{ $helper.formatDate(data.value.createdAt) }} {{ $helper.formatTime(data.value.createdAt) }}
                      </template>
                      <template v-slot:pdf="data">
                        <div v-if="data.value.pdf">
                          <print-pdf-btn :pdfResource="data.value.pdf['@id']" :pdfUrl="data.value.pdf.notCachedUrl" />
                        </div>
                      </template>
                    </table-lite>
                  </div>


                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('forms.orders')"  v-if="isTabVisible('orders')">
              <b-row>
                <b-col>

                  <orders-listing
                      v-if="item"
                      :item-obj="item"
                  />

                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-form>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import "vue3-date-time-picker/dist/main.css";
import vSelect from "vue-select";
import Datepicker from "vue3-date-time-picker";
import ImageUpload from "@/components/Inputs/ImageUpload.vue";
import PrintPdfBtn from "@/components/Buttons/PrintPdfBtn.vue";
import moment from "moment";
import TableLite from "vue3-table-lite";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import OrdersListing from "./OrdersListing.vue";
import CustomSelect from "@/components/Inputs/CustomSelect.vue";

export default {
    components: {
        Datepicker,
        vSelect,
        ImageUpload,
        TableLite,
        PrintPdfBtn,
        DeleteBtn,
        OrdersListing,
        CustomSelect
    },
    props: {
        itemObject: { Object, required: true },
        action: { type: String, default: null },
        baseUrl: { type: String, required: false, default: '/items' },
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getEnumList(['ITEM_CATEGORY', 'ITEM_STATUS', 'LAYBOARD_TYPE', 'ITEM_SIZE', 'ITEM_CONDITION', 'ITEM_CONTROLLED'], this.itemCategories, this.statuses, this.layboardTypes, this.sizes, this.itemConditions, this.itemControlleds)
        this.getEntityOptionList(['ITEM_CATEGORY', 'LOCATION', 'DEPARTMENT', 'NAVISION_TYPE', 'TYPE', 'MAINTENANCE_TYPE'], this.categories, this.locations, this.departments, this.navisionTypes, this.types, this.maintenanceTypes)

        this.statusSelect = this.item.statusSelect
        this.toolSelect = this.item.toolSelect
        this.toolsSelect = this.item.toolsSelect
        this.userSelect = this.item.userSelect
        this.itemVendors = this.item.itemVendors
        this.categorySelect = this.item.categorySelect
        this.locationSelect = this.item.locationSelect
        this.departmentSelect = this.item.departmentSelect
        this.navisionTypeSelect = this.item.navisionTypeSelect
        this.itemCategorySelect = this.item.itemCategorySelect
        this.navisionItemSelect = this.item.navisionItemSelect
        this.grometSelect = this.item.grometSelect
        this.handtoolSelect = this.item.handtoolSelect
        this.airpressSelect = this.item.airpressSelect
        this.applicatorSelect = this.item.applicatorSelect
        this.applicator2Select = this.item.applicator2Select
        this.sizeSelect = this.item.sizeSelect
        this.controlledSelect = this.item.controlledSelect
        this.layboardTypeSelect = this.item.layboardTypeSelect
        this.itemConditionSelect = this.item.itemConditionSelect
        this.typeSelect = this.item.typeSelect
        this.maintenanceTypeSelect = this.item.maintenanceTypeSelect
        if (this.item.fileUrl) {
          this.fileURL = this.item.fileUrl;
        }
        this.setFieldsVisibility()
        this.setTabsVisibility()
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        const validation = {
            itemCategorySelect: { required },
            statusSelect: {},
            categorySelect: {},
            locationSelect: {},
            typeSelect: {},
            maintenanceTypeSelect: {},
            departmentSelect: {},
            userSelect: {},
            toolSelect: {},
            toolsSelect: [],
            itemVendors: [],
            grometSelect: {},
            handtoolSelect: {},
            aipressSelect: {},
            applicatorSelect: {},
            applicator2Select: {},
            navisionItemSelect: {},
            navisionTypeSelect: {},
            itemConditionSelect: {},
            item: {
                number: {},
                name: {},
                description: {},
                model: {},
                itemInterval: {},
                lastDate: {},
                nextDate: {},
                label: {},
                picture: {},
                serviceTag: {},
                crossReference: {},
                minQuantity: {},
                piece: {},
                price: {},
                hyperlink: {},
                stock: {},
                handtoolLocation: {},
                airpressLocation: {},
                wireArea: {},
                ch: {},
                chIso: {},
                remark: {},
                minTorque: {},
                maxTorque: {},
                manufacturer: {},
                strippingLength: {}
            }
        };

        if (this.itemCategorySelect && this.itemCategorySelect.id === 'TOOL') {
            validation.item.number = { required }
            validation.item.name = { required }
            validation.categorySelect = { required }
            validation.typeSelect = { required }
            validation.locationSelect = { required }
            validation.itemConditionSelect = { required }
            validation.maintenanceTypeSelect = { required }
        }

        if (this.itemCategorySelect && this.itemCategorySelect.id === 'IT') {
            validation.item.number = { required }
            validation.item.name = { required }
            validation.typeSelect = { required }
            validation.item.model = { required }
            validation.departmentSelect = { required }
            validation.locationSelect = { required }
            validation.itemConditionSelect = { required }
        }

        if (this.itemCategorySelect && this.itemCategorySelect.id === 'LAY_BOARD') {
          validation.item.name = { required }
          validation.locationSelect = { required }
        }

        if (this.itemCategorySelect && this.itemCategorySelect.id === 'SPARE_PART') {
            validation.item.description = { required }
            validation.item.crossReference = { required }
            validation.item.minQuantity = { required }
            validation.item.piece = { required }
            validation.statusSelect = { required }
        }

        if (this.itemCategorySelect && this.itemCategorySelect.id === 'EQUIPMENT') {
            validation.item.description = { required }
            validation.item.crossReference = { required }
            validation.item.minQuantity = { required }
            validation.item.piece = { required }
            validation.statusSelect = { required }
        }

        if (this.itemCategorySelect && this.itemCategorySelect.id === 'TERMINAL_REVIEW') {
            validation.navisionItemSelect = { required }
            validation.item.wireArea = { required }
        }

        if (this.itemCategorySelect && this.itemCategorySelect.id === 'EXTRACTION') {
            validation.navisionItemSelect = { required }
            validation.handtoolSelect = { required }
        }

        if (this.itemCategorySelect && this.itemCategorySelect.id === 'TIGHTENING_TORQUES') {
            validation.navisionItemSelect = { required }
            validation.item.minTorque = { required }
            validation.item.maxTorque = { required }
            validation.item.manufacturer = { required }
        }

        if (this.itemCategorySelect && this.itemCategorySelect.id === 'NAVISION_ITEM') {
            validation.item.number = { required }
            validation.navisionTypeSelect = { required }
        }

        if (this.itemCategorySelect && this.itemCategorySelect.id === 'OFFICE') {
            validation.item.name = { required }
        }

        return validation
    },
    data() {
        return {
            row: 'row',
            item: this.itemObject,
            users: [],
            userSelect: {},
            navisionItems: [],
            navisionItemSelect: {},
            grometSelect: {},
            handtoolSelect: {},
            airpressSelect: {},
            applicatorSelect: {},
            applicator2Select: {},
            categories: [],
            categorySelect: {},
            locations: [],
            locationSelect: {},
            departments: [],
            departmentSelect: {},
            navisionTypes: [],
            types: [],
            maintenanceTypes: [],
            navisionTypeSelect: {},
            vendors: [],
            selectedVendor: null,
            itemVendors: [],
            tools: [],
            handTools: [],
            applicatorTools: [],
            toolSelect: {},
            toolsSelect: [],
            spareParts: [],
            sparePartSelect: [],
            itemCategorySelectPrev: {
              id: null,
              label: null
            },
            itemCategorySelect: {},
            itemCategories: [],
            statuses: [],
            statusSelect: {},
            sizes: [],
            itemConditionSelect: {},
            typeSelect: {},
            maintenanceTypeSelect: {},
            itemConditions: [],
            itemControlleds: [],
            sizeSelect: {},
            controlledSelect: {},
            layboardTypes: [],
            layboardTypeSelect: {},
            fieldsVisibility: [],
            tabsVisibility: [],
            fileURL: null,
            columns: [
              {
                label: this.$t("forms.vendor"),
                field: "vendor",
                sortable: false,
              },
              {
                label: this.$t("forms.vendorItemNumber"),
                field: "vendorItemNumber",
                sortable: false,
              },
              {
                label: this.$t("forms.price"),
                field: "price",
                sortable: false,
              },
              {
                label: this.$t("forms.text"),
                field: "leadTime",
                sortable: false,
              },
              {
                label: this.$t("forms.reqQuantity"),
                field: "reqQuantity",
                sortable: false,
              },
              {
                label: this.$t("forms.actions"),
                field: "actions",
                sortable: false,
                width: "120px",
              }
            ],
            historyColumns: [
              {
                label: this.$t("forms.notes"),
                field: "notes",
                sortable: false,
              },
              {
                label: this.$t("forms.createdBy"),
                field: "user",
                sortable: false,
              },
              {
                label: this.$t("forms.createdAt"),
                field: "createdAt",
                sortable: true,
              },
              {
                label: this.$t("pdf.pdf"),
                field: "pdf",
                sortable: false,
              },
            ],
            orderColumns: [
                {
                  label: this.$t("forms.id"),
                  field: "id",
                  sortable: false,
                },
                {
                  label: this.$t("forms.createdAt"),
                  field: "createdAt",
                  sortable: false,
                },
                {
                  label: this.$t("order.total"),
                  field: "total",
                  sortable: true,
                },
              ]
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        getItemHistories() {
            this.$Items
                .getResourceByUrl({ url: `${this.item['@id']}/histories` }, 'item_histories')
                .then((response) => {
                    if (response.status === 200) {
                        this.item.historiesDetails = response.data["hydra:member"]
                    }
                });
        },
        setPhoto(data) {
            if ('file' in this.item) {
                this.deleteByUrl(this.$Files, this.item.file, null);
            }
            this.item.file = data["@id"];
            this.fileURL = data.url;
            const body = {
                file: this.item.file
            }
            this.update(this.$Items, this.item.id, body, this.$t('messages.itemUpdated'));
        },
        updateItemCategorySelect() {
          this.item.categorySelect = this.categorySelect
        },
        addVendorToItem() {
          if (this.selectedVendor && this.selectedVendor.id) {
            this.itemVendors.push({
              item: this.itemObject,
              vendorSelect: this.selectedVendor,
              vendorItemNumber: null,
              price: null,
              leadTime: null,
              reqQuantity: null
            })
          }
          this.selectedVendor = null
        },
        removeItemVendor(itemVendor) {
          const index = this.itemVendors.findIndex((op) => op.id === itemVendor.id);
          if (index >= 0) {
            this.itemVendors.splice(index, 1);
          }
        },
        isOtherItemForm() {
          return this.$route.path.includes('/others') && this.item.id
        },
        setFieldsVisibility() {
            if (this.itemCategorySelectPrev.id === this.itemCategorySelect.id) {
              return;
            }

            this.fieldsVisibility = []

            const fields = [
                { name: 'itemCategorySelect', visibleFor: ['OTHER'] },
                { name: 'number', visibleFor: ['TOOL', 'IT', 'NAVISION_ITEM', 'OTHER'] },
                { name: 'name', visibleFor: ['TOOL', 'IT', 'OFFICE', 'LAY_BOARD', 'OTHER'] },
                { name: 'description', visibleFor: ['TOOL', 'IT', 'SPARE_PART', 'EQUIPMENT', 'NAVISION_ITEM'] },
                { name: 'description2', visibleFor: ['SPARE_PART', 'EQUIPMENT', 'NAVISION_ITEM', 'EXTRACTION', 'EQUIPMENT'] },
                { name: 'category', visibleFor: ['TOOL'] },
                { name: 'categorySelect', visibleFor: ['TOOL', 'IT', 'SPARE_PART', 'EQUIPMENT', 'NAVISION_ITEM'] },
                { name: 'typeSelect', visibleFor: ['TOOL', 'IT'] },
                { name: 'layboardTypeSelect', visibleFor: ['LAY_BOARD'] },
                { name: 'model', visibleFor: ['IT'] },
                { name: 'departmentSelect', visibleFor: ['IT'] },
                { name: 'locationSelect', visibleFor: ['TOOL', 'IT', 'LAY_BOARD'] },
                { name: 'itemConditionSelect', visibleFor: ['TOOL', 'IT'] },
                { name: 'maintenanceTypeSelect', visibleFor: ['TOOL'] },
                { name: 'interval', visibleFor: ['TOOL'] },
                { name: 'lastDate', visibleFor: ['TOOL'] },
                { name: 'nextDate', visibleFor: ['TOOL'] },
                // { name: 'label', visibleFor: ['TOOL'] },
                { name: 'picture', visibleFor: ['TOOL', 'EXTRACTION', 'TERMINAL_REVIEW'] },
                { name: 'userSelect', visibleFor: ['IT'] },
                { name: 'serviceTag', visibleFor: ['IT'] },
                // { name: 'toolSelect', visibleFor: ['SPARE_PART'] },
                { name: 'toolsSelect', visibleFor: ['SPARE_PART', 'EQUIPMENT'] },
                { name: 'vendorRows', visibleFor: ['SPARE_PART', 'EQUIPMENT'] },
                { name: 'crossReference', visibleFor: ['SPARE_PART', 'EQUIPMENT', 'OTHER'] },
                { name: 'minQuantity', visibleFor: ['SPARE_PART', 'EQUIPMENT'] },
                { name: 'piece', visibleFor: ['SPARE_PART', 'EQUIPMENT'] },
                { name: 'statusSelect', visibleFor: ['SPARE_PART', 'EQUIPMENT'] },
                { name: 'hyperlink', visibleFor: ['SPARE_PART', 'EQUIPMENT', 'TIGHTENING_TORQUES'] },
                { name: 'stock', visibleFor: ['TOOL', 'IT', 'SPARE_PART', 'EQUIPMENT'] },
                { name: 'navisionItemSelect', visibleFor: ['EXTRACTION', 'TERMINAL_REVIEW', 'TIGHTENING_TORQUES'] },
                { name: 'navisionTypeSelect', visibleFor: ['NAVISION_ITEM'] },
                { name: 'minTorque', visibleFor: ['TIGHTENING_TORQUES'] },
                { name: 'maxTorque', visibleFor: ['TIGHTENING_TORQUES'] },
                { name: 'manufacturer', visibleFor: ['TIGHTENING_TORQUES'] },
                { name: 'grometSelect', visibleFor: ['TERMINAL_REVIEW'] },
                { name: 'applicatorSelect', visibleFor: ['TERMINAL_REVIEW'] },
                { name: 'applicator2Select', visibleFor: ['TERMINAL_REVIEW'] },
                { name: 'handtoolSelect', visibleFor: ['EXTRACTION', 'TERMINAL_REVIEW'] },
                { name: 'handtoolLocation', visibleFor: ['TERMINAL_REVIEW'] },
                { name: 'airpressSelect', visibleFor: ['TERMINAL_REVIEW'] },
                { name: 'airpressLocation', visibleFor: ['TERMINAL_REVIEW'] },
                { name: 'strippingLength', visibleFor: ['TERMINAL_REVIEW'] },
                { name: 'wireArea', visibleFor: ['TERMINAL_REVIEW'] },
                { name: 'ch', visibleFor: ['TERMINAL_REVIEW'] },
                { name: 'chIso', visibleFor: ['TERMINAL_REVIEW'] },
                { name: 'controlledSelect', visibleFor: ['TERMINAL_REVIEW'] },
                { name: 'remark', visibleFor: ['TERMINAL_REVIEW', 'LAY_BOARD'] },
                { name: 'sizeSelect', visibleFor: ['LAY_BOARD'] },
                { name: 'note', visibleFor: ['LAY_BOARD'] },
                { name: 'statusText', visibleFor: ['LAY_BOARD'] },
            ]

            this.itemCategorySelectPrev = this.itemCategorySelect

            if (this.itemCategorySelect) {
                fields.forEach((field) => {
                    if (field.visibleFor.includes(this.itemCategorySelect.id) || field.visibleFor.length === 0) {
                        this.fieldsVisibility.push(field.name)
                    }
                })
            }
        },
        isFieldVisible(fieldName) {
            return this.fieldsVisibility.includes(fieldName)
        },
        setTabsVisibility() {
          this.tabsVisibility = []

          const tabs = [
            { name: 'vendors', visibleFor: ['TOOL', 'IT', 'SPARE_PART', 'EQUIPMENT', 'TERMINAL_REVIEW', 'NAVISION_ITEM'] },
            { name: 'orders', visibleFor: ['TOOL', 'IT', 'SPARE_PART', 'EQUIPMENT', 'TERMINAL_REVIEW', 'NAVISION_ITEM'] },
            { name: 'history', visibleFor: ['TOOL', 'IT', 'SPARE_PART', 'EQUIPMENT', 'TERMINAL_REVIEW', 'NAVISION_ITEM', 'LAY_BOARD'] },
          ]

          if (this.itemCategorySelect) {
            tabs.forEach((tab) => {
              if (tab.visibleFor.includes(this.itemCategorySelect.id) || tab.visibleFor.length === 0) {
                this.tabsVisibility.push(tab.name)
              }
            })
          }
        },
        isTabVisible(tabName) {
          if (this.item && !this.item.id) {
            return false;
          }

          return this.tabsVisibility.includes(tabName)
        },
        async onSubmit() {
            const isValid = await this.v$.$validate();

            if (isValid) {
                this.item.itemCategory = this.itemCategorySelect.id

                if (this.categorySelect && this.categorySelect.id) {
                    this.item.category = this.categorySelect.id
                }

                if (this.sizeSelect && this.sizeSelect.id) {
                    this.item.size = this.sizeSelect.id
                }

                if (this.controlledSelect && this.controlledSelect.id) {
                    this.item.controlled = this.controlledSelect.id
                }

                if (this.layboardTypeSelect && this.layboardTypeSelect.id) {
                    this.item.layboardType = this.layboardTypeSelect.id
                }

                if (this.itemConditionSelect && this.itemConditionSelect.id) {
                    this.item.itemCondition = this.itemConditionSelect.id
                }

                if (this.locationSelect && this.locationSelect.id) {
                    this.item.location = this.locationSelect.id
                }

                if (this.typeSelect && this.typeSelect.id) {
                    this.item.type = this.typeSelect.id
                }

                if (this.maintenanceTypeSelect && this.maintenanceTypeSelect.id) {
                    this.item.maintenanceType = this.maintenanceTypeSelect.id
                }

                if (this.departmentSelect && this.departmentSelect.id) {
                    this.item.department = this.departmentSelect.id
                }

                if (this.navisionTypeSelect && this.navisionTypeSelect.id) {
                    this.item.navisionType = this.navisionTypeSelect.id
                }

                if (this.item.stock) {
                    this.item.stock = parseInt(this.item.stock)
                } else {
                    this.item.stock = null
                }

                if (this.item.itemInterval) {
                    this.item.itemInterval = parseInt(this.item.itemInterval)
                } else {
                    this.item.itemInterval = null
                }

                if (this.item.lastDate) {
                    this.item.lastDate = moment(this.item.lastDate).format()
                } else {
                    this.item.lastDate = null
                }

                if (this.item.nextDate) {
                    this.item.nextDate = moment(this.item.nextDate).format()
                } else {
                    this.item.nextDate = null
                }

                if (this.userSelect && this.userSelect.id) {
                    this.item.user = this.userSelect.id
                } else {
                  this.item.user = null
                }

                if (this.toolSelect && this.toolSelect.id) {
                    this.item.tool = this.toolSelect.id
                } else {
                    this.item.tool = null
                }

                if (this.toolsSelect && this.toolsSelect.length > 0) {
                  this.item.tools = this.toolsSelect.map((item) => {
                    return item.id
                  });
                } else {
                    this.item.tools = []
                }

                if (this.itemVendors && this.itemVendors.length > 0) {
                  this.item.vendors = this.itemVendors.map((itemVendor) => {
                    return {
                      item: itemVendor.item['@id'],
                      vendor: itemVendor.vendorSelect.id,
                      vendorItemNumber: itemVendor.vendorItemNumber,
                      price: itemVendor.price,
                      leadTime: itemVendor.leadTime,
                      reqQuantity: parseInt(itemVendor.reqQuantity),
                    };
                  });
                } else {
                  this.item.vendors = []
                }

                if (this.item.historiesDetails && this.item.historiesDetails.length > 0) {
                  const historiesTmp = this.item.historiesDetails
                  this.item.histories = historiesTmp.map((itemHistory) => {
                    return itemHistory['@id']
                  });
                }

                if (this.statusSelect && this.statusSelect.id) {
                    this.item.status = this.statusSelect.id
                }

                if (this.item.minQuantity) {
                    this.item.minQuantity = parseInt(this.item.minQuantity)
                }

                if (this.item.piece) {
                    this.item.piece = parseInt(this.item.piece)
                }

                if (this.navisionItemSelect && this.navisionItemSelect.id ) {
                    this.item.navisionItem = this.navisionItemSelect.id
                }

                if (this.grometSelect && this.grometSelect.id ) {
                    this.item.gromet = this.grometSelect.id
                } else {
                  this.item.gromet = null
                }

                if (this.applicatorSelect && this.applicatorSelect.id ) {
                    this.item.applicator = this.applicatorSelect.id
                } else {
                  this.item.applicator = null
                }

                if (this.applicator2Select && this.applicator2Select.id ) {
                    this.item.applicator2 = this.applicator2Select.id
                } else {
                  this.item.applicator2 = null
                }

                if (this.handtoolSelect && this.handtoolSelect.id ) {
                    this.item.handtool = this.handtoolSelect.id
                } else {
                  this.item.handtool = null
                }

                if (this.airpressSelect && this.airpressSelect.id ) {
                    this.item.airpress = this.airpressSelect.id
                } else {
                  this.item.airpress = null
                }

                if (this.action === 'update' && this.item.insertedBy) {
                    this.item.insertedBy = this.item.insertedBy['@id']
                }

                this.item.itemVendors = null

                if (this.action) {
                    this.action === "add"
                        ? this.create(
                            this.$Items,
                            this.item,
                            this.$t("messages.itemCreated"),
                            null,
                            this.success,
                            this.error
                        )
                        : this.update(
                            this.$Items,
                            this.item.id,
                            this.item,
                            this.$t("messages.itemUpdated"),
                            null,
                            this.success,
                            this.error
                        );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        success(response) {
          if (this.action === 'add') {
            this.$router.push(this.$route.path.replace('/add', `/${response.data.id}/edit`))
          }

          this.$emit("clearAction");
          this.getItemHistories();
        },
        error() {
            this.$emit("clearAction");
        }
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

